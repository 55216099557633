import {
  Component,
  For,
  Match,
  Show,
  Switch,
  createResource,
  createEffect,
  on,
  createSignal,
  createMemo,
} from "solid-js";
import { useSearchParams, A } from "@solidjs/router";
import { debounce } from "@solid-primitives/scheduled";
import { createStore, produce } from "solid-js/store";

import { Icon } from "solid-heroicons";
import {
  xMark,
  arrowUturnRight,
  tag,
  checkCircle,
  xCircle,
} from "solid-heroicons/outline";
import { tag as tagSolid } from "solid-heroicons/solid";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { PT, PTLabels } from "~/utils/products";
import {
  Line,
  SkuInput,
  PlantSelector,
  CuttingDropdown,
  Quantity,
  Amount,
  Dimensions,
  Layer,
  Allowance,
  WedgeLocks,
  RailLength,
  FrameSize,
  LinearFeet,
  BoxQty,
} from "~/components/ordering/express/components";

import { TextFieldInput, ProgressBar } from "~/components/inputs";
import { facilityMapping } from "~/components/ordering/order-page";
import {
  lineValidation,
  lineNotices,
  lineConsents,
  formattedLine,
  formattedTrackingLine,
} from "~/components/ordering/express/utils";
import { stockThreshold } from "~/utils/threshold";
import { BaseLoader } from "~/components/utility";
import { EventType } from "@solid-primitives/analytics";
import { createChunkedRequest } from "~/components/ordering/utils/createChunkedRequest";
import { Modal } from "~/components/utility";
import { imageUrl } from "~/utils/products";
import { InfoBox } from "~/components/utility";
import Button from "~/components/Button";
import { ProductImage } from "~/components/product";
import { Product } from "~/services/roma-api/products/types";

import type {
  ExpressStore,
  ExpressStoreLine,
  ExpressLineProps,
} from "~/components/ordering/express/types";

export default function ExpressOrder() {
  const { session, isPartner, permission } = useSessionContext();
  const { track } = useSiteContext();
  const [aPriceResourceIsFiring, setAPriceResourceIsFiring] =
    createSignal(false);
  const [addCartLoading, setAddCartLoading] = createSignal(false);
  const [_, setParams] = useSearchParams();
  const [showAddCartModal, setShowAddCartModal] = createSignal(false);
  const options = [
    { label: "Length", value: PT.LENGTH },
    { label: "Chop", value: PT.CHOP },
    { label: "Join", value: PT.JOIN },
    { label: "Rail", value: PT.RAIL },
    { label: "Photo Frame", value: PT.PHOTOFRAME },
    { label: "Gallery Frame", value: PT.GALLERYFRAME },
    { label: "Box", value: PT.BOX },
  ];

  // * STORE * //
  const [store, setStore] = createStore<ExpressStore>({
    lines: options.reduce((memo, option) => {
      memo[option.value] = [];
      return memo;
    }, {} as Record<string, ExpressStoreLine[]>),
    get lineCount() {
      let count = 0;
      for (const i in this.lines) {
        count += this.lines[i].length;
      }
      return count;
    },
    get Totals() {
      const obj = {
        amount: 0,
        tax: 0,
        discount: 0,
        subtotal: 0,
        total: 0,
        errors: 0,
        validLines: 0,
        errorLines: 0,
      };
      for (const type in this.lines) {
        for (const line of this.lines[type]) {
          if (line.Valid && !line.pricingError()) {
            obj.amount += line.pricing?.latest?.Amount ?? 0;
            obj.tax += line.pricing?.latest?.Tax ?? 0;
            obj.discount += Math.abs(line.pricing?.latest?.Discount ?? 0);
            obj.subtotal += line.pricing?.latest?.SubTotal ?? 0;
            obj.total += line.pricing?.latest?.Total ?? 0;

            obj.validLines += 1;
          } else if (line.Errors || line.pricingError()) {
            obj.errors += Object.keys(line.Errors!).length;
            obj.errorLines += 1;
          }
        }
      }
      return obj;
    },
  });

  // * DEFAULT LINE * //

  const defaultLine = (token: string, type: PT, props?: any) => {
    let controller: AbortController;
    const [pricingError, setPricingError] = createSignal(false);
    // ? ^^ Why are pricing errors not handled in line validation? Because it
    // ? ended up causing some circular dependency / endless fetching nonsense
    // ? with the way things are structured. So it lives on its own until this
    // ? express order page is re-architected.
    const [pricing, { refetch: getPricing }] = createResource(
      async (_, { refetching: line }) => {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        if (!line) return {};
        try {
          setAPriceResourceIsFiring(true);
          const response = await fetch(
            `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify([line]),
              signal: controller.signal,
            }
          );
          if (response.ok) {
            const data = await response.json();
            setAPriceResourceIsFiring(false);
            const obj = {
              ...data[0],
              // Amount is the PRETAX Total, with discount
              Total: data[0].Amount + data[0].Tax,
              SubTotal: data[0].Amount + Math.abs(data[0].Discount),
            };
            setPricingError(false);
            return obj;
          } else {
            const errorText = await response.text();
            throw new Error(
              `Request failed with status code ${response.status}. Error: ${errorText}`
            );
          }
        } catch (error) {
          console.error(error);
          setAPriceResourceIsFiring(false);
          setPricingError(true);
          return { Error: error };
        }
      },
      { ssrLoadFrom: "initial", initialValue: {} }
    );
    const [productData, { refetch }] = createResource(
      async function (_, { refetching: sku }) {
        if (typeof sku !== "string") return;
        try {
          const response = await fetch(
            `${import.meta.env.VITE_ROMA_API}/products/${sku}`
          );
          if (response.ok) {
            const data = await response.json();
            if (data.SKU) {
              data.valid = true;
              data.availableAsType = data.AvailableAs.includes(type);
              data.isStretcher = data.Profile === "Stretcher";

              if (type === PT.BOX && data.AvailableAs.includes(type)) {
                try {
                  const response = await fetch(
                    `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify([
                        {
                          Type: "box",
                          Quantity: 1,
                          Plant: "",
                          SubItems: [{ SKU: sku, Moulding: sku, Length: 1 }],
                        },
                      ]),
                    }
                  );

                  if (response.ok) {
                    const resp = await response.json();
                    data.BoxQty = resp[0].Quantity;
                  } else {
                    const errorText = await response.text();
                    throw new Error(
                      `Box Quantity Request failed with status code ${response.status}. Error: ${errorText}`
                    );
                  }
                } catch (error) {
                  console.log(error);
                }
              }

              return data as Product & { BoxQty: number };
            }
          } else {
            const errorText = await response.text();
            throw new Error(
              `Request failed with status code ${response.status}. Error: ${errorText}`
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
      {
        ssrLoadFrom: "initial",
        initialValue: { valid: false, availableAsType: false },
      }
    );
    const [plantDetails] = createResource(
      () =>
        (productData?.latest as Product)?.SKU
          ? [(productData.latest as Product).SKU, productData.latest]
          : false,
      async ([sku, productData]) => {
        try {
          const response = await fetch(
            `${import.meta.env.VITE_ROMA_API}/products/${sku}/prices`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            const arr = Object.entries(
              data?.Inventory as Record<string, number>
            ).reduce((memo: any[], [plant, qty]: [string, number]) => {
              // @ts-ignore
              memo.push({
                label: `${facilityMapping[plant]}: ${
                  stockThreshold(
                    +qty,
                    (productData as Product).Category,
                    (productData as Product).Discontinued
                  ).dropdownContent
                }`,
                //! Tariff Logic - blocking selecting CA as a shipping option. Remove if/when resolved.
                disabled: plant === "BP01",
                // Reason for overwriting plant value - API requires an empty string
                // to represent the default plant. When payload is generated, defaultPlant
                // is replaced with "". Using an empty string here causes issues with the dropdown component.
                value: plant === data.Plant ? "defaultPlant" : plant,
              });
              return memo;
            }, []);
            return {
              defaultPlant: data.Plant,
              plantList: arr,
              stockLevels: data.Inventory,
            };
          } else {
            const resp = await response.json();
            const errorObj: Record<string, string> = { status: "error" };
            if (resp.Code) {
              errorObj.Error = { ...resp };
            }
            console.error(resp);
            return errorObj;
          }
        } catch (error) {
          console.log(error);
        }
      },
      {
        ssrLoadFrom: "initial",
        initialValue: {
          plantList: [],
          defaultPlant: undefined,
          stockLevels: {},
        },
      }
    );
    const [obj]: any = createStore({
      Type: type,
      SearchVal: undefined,
      Plant: "defaultPlant",
      Allowance: "1/8",
      Quantity: [PT.JOIN, PT.CHOP, PT.RAIL, PT.BOX].includes(type) ? 1 : undefined,
      ...props,
      productData,
      plantDetails,
      pricing,
      pricingError,

      get Errors() {
        return lineValidation(this);
      },
      get Notices() {
        return lineNotices(this);
      },
      get Consents() {
        return lineConsents(this);
      },
      get availableAsType(): boolean {
        if (!this.productData.latest) return false;
        return this?.productData?.latest.AvailableAs?.includes(this.Type);
      },
      get isStretcher(): boolean {
        if (!this.productData.latest) return false;
        return this?.productData?.latest.isStretcher;
      },
      get isFloater(): boolean {
        if (!this.productData.latest) return false;
        return this?.productData.latest.Profile === "Floater";
      },
      get Valid() {
        return Object.keys(this.Errors).length === 0;
      },
    });

    createEffect(
      on(
        () => obj.SearchVal,
        debounce(() => refetch(obj.SearchVal), 250)
        // { defer: true }
      )
    );

    createEffect(() => {
      if (obj.Valid === true) {
        getPricing(formattedLine(type, obj));
      }
    });
    return obj;
  };

  // * COMPONENTS * //

  type OrderTypes =
    | PT.LENGTH
    | PT.RAIL
    | PT.CHOP
    | PT.JOIN
    | PT.PHOTOFRAME
    | PT.GALLERYFRAME
    | PT.BOX
    | PT.CORNERSAMPLE;

  const headings: Record<OrderTypes, string[]> = {
    [PT.LENGTH]: [
      "Moulding",
      "QTY",
      "Ships From",
      "Cutting Instructions",
      "Amount",
    ],
    [PT.CHOP]: [
      "Moulding",
      "QTY",
      "Ships From",
      "Dimensions",
      "Layer",
      "Allowance",
      "Wedge Locks",
      "Amount",
    ],
    [PT.JOIN]: [
      "Moulding",
      "QTY",
      "Ships From",
      "Dimensions",
      "Layer",
      "Allowance",
      "Amount",
    ],
    [PT.RAIL]: [
      "Moulding",
      "QTY",
      "Ships From",
      "Length",
      "Layer",
      "Allowance",
      "Amount",
    ],
    [PT.PHOTOFRAME]: ["Moulding", "QTY", "Size", "Amount"],
    [PT.GALLERYFRAME]: ["Moulding", "QTY", "Size", "Amount"],
    [PT.CORNERSAMPLE]: ["Moulding", "Amount"],
    [PT.BOX]: ["Moulding", "QTY", "Ships From", "Box Footage", "Amount"],
  };

  const tdClass = "px-1 py-4 text-sm text-gray-500 whitespace-nowrap";

  const Length: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <CuttingDropdown {...props} />
        </td>
      </>
    );
  };
  const Rail: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <div class="flex items-center">
            <RailLength {...props} />
          </div>
        </td>
        <td class={`${tdClass}`}>
          <Layer {...props} />
        </td>
        <td class={`${tdClass}`}>
          <Allowance {...props} />
        </td>
      </>
    );
  };
  const Chop: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <div class="flex items-center">
            <Dimensions {...props} />
          </div>
        </td>
        <td class={`${tdClass}`}>
          <Layer {...props} />
        </td>
        <td class={`${tdClass}`}>
          <Allowance {...props} />
        </td>
        <td class={`${tdClass}`}>
          <WedgeLocks {...props} />
        </td>
      </>
    );
  };
  const Join: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <div class="flex items-center">
            <Dimensions {...props} />
          </div>
        </td>
        <td class={`${tdClass}`}>
          <Layer {...props} />
        </td>
        <td class={`${tdClass}`}>
          <Allowance {...props} />
        </td>
      </>
    );
  };
  const PhotoFrame: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <FrameSize {...props} />
        </td>
      </>
    );
  };
  const GalleryFrame: Component<ExpressLineProps> = (props) => {
    return (
      <>
        <td class={`${tdClass}`}>
          <FrameSize {...props} />
        </td>
      </>
    );
  };
  const Box: Component<ExpressLineProps> = (props) => {
    return (
      <td class={`${tdClass}`}>
        <BoxQty {...props} />
      </td>
    );
  };

  const AddCartModal: Component = () => {
    type Status = "SUCCESS" | "PARTIAL" | "FAILURE";
    const [addCartStatus, setAddCartStatus] = createSignal<{
      status: string | Status;
      result: (boolean | null)[];
      errors: number[];
    }>();

    const payload = createMemo(() => {
      let arr = [];
      for (const type in store.lines) {
        for (const line of store.lines[type]) {
          if (line.Valid && !line.pricingError()) {
            arr.push(formattedLine(line.Type, line));
          }
        }
      }
      return arr;
    });
    // let payload: any[] = [];
    // for (const type in store.lines) {
    //   for (const line of store.lines[type]) {
    //     if (line.Valid && !line.pricingError())
    //       payload.push(formattedLine(line.Type, line));
    //   }
    // }

    // formats a payload of the valid line items for GA 'add_to_cart' event,
    // triggered on succesfull add to cart:

    //TODO: Add formatting for meta pixel

    let trackingPayload = {
      currency: session()?.currency,
      value: store.Totals.total,
      items: [] as any,
    };
    for (const type in store.lines) {
      for (const line of store.lines[type]) {
        if (line.Valid && !line.pricingError())
          trackingPayload.items.push(formattedTrackingLine(line.Type, line));
      }
    }

    const { completionState, request, skus, actionIsPending, pendingPosition } =
      createChunkedRequest(
        payload,
        `${import.meta.env.VITE_ROMA_API}/cart/${session()?.cart_id}/lines`,
        20
      );

    const addBulkToCart = async () => {
      const payloadClone = structuredClone(payload());

      if (store.Totals.errorLines > 0) {
        const ok = confirm(
          `This order contains ${store.Totals.errorLines} item${
            store.Totals.errorLines > 1 ? "s" : ""
          } with errors.\n\nItems containing errors/missing details will not be added to your cart. \n\nWould you like to proceed and add only valid items to your cart?`
        );
        if (!ok) return;
      }
      if (payloadClone.length === 0) return;
      setAddCartLoading(true);
      const resp = await request();

      // iterate over response array and remove successfull (true) items from store, using
      // the original payload array to reference Type, needed to access lines of the store.
      // j resets in each new group, and increments when an error (false) item is found in
      // response array
      let group = "";
      let j = 0;
      for (const i in resp.result) {
        const index = parseInt(i);
        const item = resp.result[index];
        if (group !== payloadClone[index]!.Type) {
          group = payloadClone[index]!.Type;
          j = 0;
        }
        if (item === true) {
          setStore(produce((dataStore) => dataStore.lines[group].splice(j, 1)));
        } else {
          j++;
        }
      }

      setAddCartStatus(resp);
      if (resp.status === "SUCCESS") {
        // @ts-expect-error
        track(EventType.Event, { category: "add_to_cart", ...trackingPayload });
      }
      setAddCartLoading(false);
    };

    const scrollToIndex = (index: string) => {
      if (!index) return;
      const container = document.getElementById(
        "scrollContainer"
      ) as HTMLUListElement;
      const target = document.querySelector(
        `[data-item-index="${index}"]`
      ) as HTMLLIElement;

      if (container && target) {
        container.scrollTop = target.offsetTop;
      }
    };

    createEffect(() => {
      if (!pendingPosition()) return;
      setTimeout(
        () => scrollToIndex(pendingPosition()?.toString() as string),
        300
      );
    });

    return (
      <div class="flex flex-col gap-1 text-sm">
        <p>Add the following items to your cart? ({payload?.length} Items)</p>
        <Show when={store.Totals.errorLines > 0}>
          <InfoBox type="warning">
            {`This order contains ${store.Totals.errorLines} item${
              store.Totals.errorLines > 1 ? "s" : ""
            } with errors. Items containing errors/missing details will not be added to your cart.`}
          </InfoBox>
        </Show>
        <ul
          id="scrollContainer"
          class="grid my-4 max-h-52 overflow-y-auto gap-2 scroll-smooth relative"
        >
          <For each={payload()}>
            {(item, index) => (
              <li
                class="flex  gap-2 items-center border text-sm"
                data-item-index={index()}
              >
                <div class="w-8 h-8 shrink-0 grow-0 flex bg-roma-grey p-0.5 justify-center items-center  ">
                  <ProductImage
                    src={imageUrl(item!.SubItems[0].Moulding, "moulding", 1)}
                    alt=""
                    height={32}
                    width={32}
                    quality={100}
                  />
                </div>
                <div class="flex">
                  <p class="font-light text-sm">{item?.SubItems[0].Moulding}</p>
                  <p class="font-light text-sm">
                    <span class="mx-1">/</span>
                    {PTLabels[item!.Type]}
                    <span class="mx-1 text-xs">&#10005;</span>
                    {item?.Type === PT.LENGTH
                      ? item.SubItems[0].Length + "ft"
                      : item?.Quantity}
                  </p>
                  <Show when={item?.Tag}>
                    <p class="font-light">
                      <span class="mx-1">/</span>
                      {item?.Tag}
                    </p>
                  </Show>
                </div>
                <div class="ml-auto mr-1">
                  <Switch>
                    <Match when={skus[index()] === null && actionIsPending()}>
                      <div class="mr-0.5">
                        <BaseLoader width={4} height={4} class="!mr-0" />
                      </div>
                    </Match>
                    <Match when={skus[index()] === true}>
                      <Icon
                        path={checkCircle}
                        class="w-5 h-5 text-success-green"
                      />
                    </Match>
                    <Match when={skus[index()] === false}>
                      <Icon path={xCircle} class="w-5 h-5 text-red-500" />
                    </Match>
                  </Switch>
                </div>
              </li>
            )}
          </For>
        </ul>
        <Show when={actionIsPending()}>
          <ProgressBar
            val={completionState() ? (completionState() as number) * 100 : 0}
            label="Progress"
          />
          <div class="bg-roma-grey text-roma-dark-grey my-2 p-3 text-sm text-center rounded-md">
            Do not close window while adding to cart
          </div>
        </Show>
        <Switch>
          <Match when={addCartStatus()?.status === "PARTIAL"}>
            <InfoBox type="warning" class="mb-3 text-xs">
              Something went wrong while attempting to add some items to your
              cart. Please try again shortly, or call Customer Care at{" "}
              <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                1-800-263-2322
              </a>{" "}
              for support. We apologize for the inconvenience.
              <br />
            </InfoBox>
            <Button
              class="w-full"
              style="outline"
              href={`/checkout/${session()?.cart_id}/`}
            >
              View Cart
            </Button>
          </Match>
          <Match when={addCartStatus()?.status === "SUCCESS"}>
            <div class="flex flex-col items-center gap-4 py-2 w-full">
              <Icon
                path={checkCircle}
                class="text-success-green w-10 h-10"
                width="40px"
                height="40px"
              />
              <p class="text-base text-center">
                Items were successfully added!
              </p>
              <div class="w-full">
                <Button
                  class="w-full"
                  style="outline"
                  href={`/checkout/${session()?.cart_id}/`}
                >
                  View Cart
                </Button>
              </div>
            </div>
          </Match>
          <Match when={addCartStatus()?.status === "FAILURE"}>
            <InfoBox type="error" class="mb-3">
              Something went wrong while attempting to add these items to your
              cart. Please try again shortly, or call Customer Care at{" "}
              <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                1-800-263-2322
              </a>{" "}
              for support. We apologize for the inconvenience.
            </InfoBox>
          </Match>
        </Switch>
        <div class="w-full pt-2">
          <Show
            when={
              !actionIsPending() &&
              !["SUCCESS", "PARTIAL"].includes(
                addCartStatus()?.status as Status
              )
            }
          >
            <Button
              onClick={addBulkToCart}
              class="w-full"
              disabled={payload()?.length === 0 || addCartLoading()}
            >
              Add to Cart
            </Button>
          </Show>
        </div>
      </div>
    );
  };

  const debounceTag = debounce((type: PT, index: number, val: string) => {
    setStore("lines", type, index, "TagName", val);
  }, 500);

  return (
    <>
      <div class="mx-auto px-4 my-7 max-w-[1824px]">
        {/* HEADER */}
        <div class="grid grid-cols-2 pb-7">
          <h2 class="col-span-1 text-3xl">Express Order</h2>
          <div class="col-span-1 flex justify-end">
            <Show
              when={isPartner()}
              fallback={
                <button
                  onClick={() => {
                    setParams({ signIn: true });
                  }}
                  class="border rounded-md border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                >
                  Sign In
                </button>
              }
            >
              <span class="isolate inline-flex rounded-md shadow-sm">
                <For each={options}>
                  {(option, index) => (
                    <button
                      disabled={!permission.PLACEORDER}
                      type="button"
                      classList={{
                        "-ml-px": index() !== 0,
                        "rounded-l-md": index() == 0,
                        "rounded-r-md": index() == options.length - 1,
                      }}
                      class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-roma-blue focus:outline-none focus:ring-1 focus:ring-roma-blue whitespace-nowrap disabled:cursor-not-allowed"
                      onClick={() => {
                        track(EventType.Event, {
                          category: "express",
                          action: "add_item",
                          value: option.value,
                        });
                        setStore(
                          produce((data) => {
                            data.lines[option.value].push(
                              defaultLine(session()?.token!, option.value)
                            );
                          })
                        );
                      }}
                    >
                      {option.label}
                    </button>
                  )}
                </For>
              </span>
            </Show>
          </div>
        </div>
        <hr />

        {/* PERMISSION BLOCK */}
        <Show
          when={isPartner() && permission.PLACEORDER}
          fallback={
            <Switch>
              <Match when={!isPartner()}>
                <div class="my-20 text-center"> Please sign in to continue</div>
              </Match>
              <Match when={isPartner() && !permission.PLACEORDER}>
                <div class=" py-10 px-2">
                  <div class="bg-roma-grey rounded-md max-w-2xl min-h-[60vh] mx-auto shadow-sm flex items-center justify-center px-4">
                    <p class="max-w-md text-center">
                      Your Roma Partner account is not set up to place orders.
                      Please{" "}
                      <A href="/support" class="text-roma-blue">
                        contact support
                      </A>{" "}
                      for more details.
                    </p>
                  </div>
                </div>
              </Match>
            </Switch>
          }
        >
          {/* MAIN */}
          <main class="my-10 space-y-10 mx-px">
            <Show
              when={store.lineCount != 0}
              fallback={
                <div class="p-10 text-center">
                  Add items to begin your express order.
                </div>
              }
            >
              <For each={options}>
                {(option) => (
                  <Show when={store.lines[option.value].length !== 0}>
                    <div>
                      <h2 class="text-2xl my-0">{option.label}</h2>
                      <div class="px-4 mb-5 sm:px-6 lg:px-8">
                        <div class="mt-4 flex flex-col">
                          <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-top">
                              <div class="shadow ring-1 ring-black ring-opacity-5">
                                <table class="min-w-full divide-y divide-gray-300 ">
                                  <thead class="bg-faint-blue sticky top-0 z-10 backdrop-blur-md">
                                    <tr>
                                      <For
                                        each={
                                          headings[option.value as OrderTypes]
                                        }
                                      >
                                        {(title) => (
                                          <th
                                            scope="col"
                                            class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 whitespace-nowrap"
                                            classList={{
                                              "w-[100px] text-right":
                                                title === "Amount",
                                            }}
                                          >
                                            {title}
                                          </th>
                                        )}
                                      </For>
                                      <th
                                        scope="col"
                                        class="relative py-3 pl-3 pr-4 sm:pr-6 w-[100px]"
                                      >
                                        <span class="sr-only">Edit</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <For each={store.lines[option.value]}>
                                    {(line, index) => {
                                      const [showTag, setShowTag] =
                                        createSignal(false);

                                      return (
                                        <Line
                                          store={store}
                                          setter={setStore}
                                          type={option.value}
                                          index={index}
                                        >
                                          <tr>
                                            <td>
                                              <SkuInput
                                                store={store}
                                                setter={setStore}
                                                type={option.value}
                                                index={index}
                                              />
                                            </td>
                                            <Show
                                              when={
                                                option.value !== PT.CORNERSAMPLE
                                              }
                                            >
                                              <td class={`${tdClass}`}>
                                                <Show
                                                  when={
                                                    option.value !== PT.LENGTH
                                                  }
                                                  fallback={
                                                    <LinearFeet
                                                      store={store}
                                                      setter={setStore}
                                                      type={option.value}
                                                      index={index}
                                                    />
                                                  }
                                                >
                                                  <Quantity
                                                    store={store}
                                                    setter={setStore}
                                                    type={option.value}
                                                    index={index}
                                                  />
                                                </Show>
                                              </td>
                                            </Show>
                                            <Show
                                              when={
                                                ![
                                                  PT.GALLERYFRAME,
                                                  PT.PHOTOFRAME,
                                                  PT.CORNERSAMPLE,
                                                ].includes(option.value)
                                              }
                                            >
                                              <td class={`${tdClass}`}>
                                                <PlantSelector
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </td>
                                            </Show>

                                            <Switch>
                                              <Match
                                                when={option.value == PT.LENGTH}
                                              >
                                                <Length
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={option.value == PT.RAIL}
                                              >
                                                <Rail
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={option.value == PT.CHOP}
                                              >
                                                <Chop
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={option.value == PT.JOIN}
                                              >
                                                <Join
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={
                                                  option.value === PT.PHOTOFRAME
                                                }
                                              >
                                                <PhotoFrame
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={
                                                  option.value ===
                                                  PT.GALLERYFRAME
                                                }
                                              >
                                                <GalleryFrame
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                              <Match
                                                when={option.value === PT.BOX}
                                              >
                                                <Box
                                                  store={store}
                                                  setter={setStore}
                                                  type={option.value}
                                                  index={index}
                                                />
                                              </Match>
                                            </Switch>
                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right w-[100px]">
                                              <Amount
                                                store={store}
                                                setter={setStore}
                                                type={option.value}
                                                index={index}
                                              />
                                            </td>
                                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-[100px]">
                                              <div class="flex gap-1">
                                                <button
                                                  aria-label="Add tag name for this product"
                                                  title="Add tag name for this product"
                                                  onClick={() =>
                                                    setShowTag(!showTag())
                                                  }
                                                  class="p-1.5 text-roma-dark-grey hover:text-black transition-[color] border border-gray-300 rounded-md"
                                                >
                                                  <Icon
                                                    path={
                                                      store.lines[option.value][
                                                        index()
                                                      ].TagName
                                                        ? tagSolid
                                                        : tag
                                                    }
                                                    class="w-5"
                                                    classList={{
                                                      "text-roma-blue":
                                                        !!store.lines[
                                                          option.value
                                                        ][index()].TagName,
                                                    }}
                                                  />
                                                </button>

                                                <button
                                                  aria-label="Delete item"
                                                  title="Delete item"
                                                  onClick={() => {
                                                    track(EventType.Event, {
                                                      category: "express",
                                                      action: "delete_item",
                                                    });
                                                    setStore(
                                                      produce((store) =>
                                                        store.lines[
                                                          option.value
                                                        ].splice(index(), 1)
                                                      )
                                                    );
                                                  }}
                                                  class="p-1.5 text-roma-dark-grey hover:text-red-500 transition-[color] border border-gray-300 rounded-md"
                                                >
                                                  <Icon
                                                    path={xMark}
                                                    class="w-5"
                                                  />
                                                </button>
                                                {/* DUPLICATE FUNCTION - pass all properties of object into new defaultLine  */}
                                                {/* <button
                                                onClick={() =>
                                                  // console.log("ITEM", unwrap(store.lines[option.value][index()]))
                                                  setStore(
                                                    produce((store) => {
                                                      let newLine = defaultLine(
                                                        session.token,
                                                        option.value,
                                                        unwrap(store.lines[option.value][index()])
                                                        // {
                                                        //   SearchVal:
                                                        //     store.lines[
                                                        //       option.value
                                                        //     ][index()]
                                                        //       .SearchVal,
                                                        // }
                                                      );

                                                      store.lines[
                                                        option.value
                                                      ].splice(
                                                        index() + 1,
                                                        0,
                                                        newLine
                                                      );
                                                    })
                                                  )
                                                }
                                                class="p-1.5 text-roma-dark-grey hover:text-black transition-[color] border border-gray-300 rounded-md"
                                              >
                                                <Icon
                                                  path={square_2Stack}
                                                  class="w-5"
                                                />
                                              </button> */}
                                                <button
                                                  aria-label={`New ${option.value} line`}
                                                  title={`New ${option.value} line`}
                                                  onClick={() => {
                                                    setStore(
                                                      produce((data) => {
                                                        data.lines[
                                                          option.value
                                                        ].push(
                                                          defaultLine(
                                                            session()?.token!,
                                                            option.value
                                                          )
                                                        );
                                                      })
                                                    );
                                                  }}
                                                  class="p-1.5 text-roma-dark-grey hover:text-black transition-[color] border border-gray-300 rounded-md"
                                                >
                                                  <Icon
                                                    path={arrowUturnRight}
                                                    class="w-5 transform rotate-180"
                                                  />
                                                </button>
                                              </div>
                                              <Show when={showTag()}>
                                                <div class="mt-1">
                                                  <TextFieldInput
                                                    placeholder="Tag Item"
                                                    value={
                                                      store.lines[option.value][
                                                        index()
                                                      ].TagName
                                                    }
                                                    onChange={(val) => {
                                                      debounceTag(
                                                        option.value,
                                                        index(),
                                                        val
                                                      );
                                                    }}
                                                    maxLength={35}
                                                  />
                                                </div>
                                              </Show>
                                            </td>
                                          </tr>
                                        </Line>
                                      );
                                    }}
                                  </For>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Show>
                )}
              </For>
            </Show>
            <hr class="my-10 block" />
          </main>
          {/* FOOTER / TOTALS */}
          <div class="bg-white/50 z-10 box-border backdrop-blur-md sticky border inset-x-0 bottom-0 mb-8 p-2 flex justify-end items-center gap-2 text-center text-roma-dark-grey">
            <div class="grow flex items-center justify-start text-sm">
              <Show when={store.Totals.errors > 0}>
                <p class="px-5">
                  <span class="text-red-500">{store.Totals.errors}</span> Errors
                  on order
                </p>
              </Show>
            </div>
            <div class="flex flex-col items-start  border-r px-4">
              <p class="text-xs">Items:</p>
              <p>{store.lineCount}</p>
            </div>
            <div class="flex flex-col items-start  border-r px-4">
              <p class="text-xs">Subtotal:</p>
              <Show
                when={!aPriceResourceIsFiring()}
                fallback={<BaseLoader width={4} height={4} />}
              >
                <p>${store.Totals.subtotal.toFixed(2)}</p>
              </Show>
            </div>
            <div class="flex flex-col items-start  border-r px-4">
              <p class="text-xs">Discount:</p>
              <Show
                when={!aPriceResourceIsFiring()}
                fallback={<BaseLoader width={4} height={4} />}
              >
                <p>(${store.Totals.discount.toFixed(2)})</p>
              </Show>
            </div>
            <div class="flex flex-col items-start  border-r px-4">
              <p class="text-xs">Tax:</p>
              <Show
                when={!aPriceResourceIsFiring()}
                fallback={<BaseLoader width={4} height={4} />}
              >
                <p>${store.Totals.tax.toFixed(2)}</p>
              </Show>
            </div>
            <div class="flex flex-col items-start px-4">
              <p class="text-xs">Total:</p>
              <Show
                when={!aPriceResourceIsFiring()}
                fallback={<BaseLoader width={4} height={4} />}
              >
                <p>${store.Totals.total.toFixed(2)}</p>
              </Show>
            </div>
            <button
              aria-label="Add to cart"
              class="bg-roma-blue rounded-sm text-white px-3 py-2 col-end-[-1] disabled:cursor-not-allowed disabled:bg-roma-medium-grey"
              onClick={() => setShowAddCartModal(true)}
              disabled={store.Totals.validLines === 0 || addCartLoading()}
            >
              <Show when={addCartLoading()} fallback={"Add to Cart"}>
                <div class="flex items-center justify-center">
                  <BaseLoader width={5} height={5} />
                </div>
              </Show>
            </button>
          </div>
        </Show>
      </div>
      {/* MODAL */}
      <Modal
        open={showAddCartModal}
        onClose={() => {
          setShowAddCartModal(false);
          // setAddCartStatus(undefined);
        }}
        title="Add to Cart"
      >
        <AddCartModal />
      </Modal>
    </>
  );
}
